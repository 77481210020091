export default class StockGameModel {
  constructor(){
    this.uid = '';
    this.round = 0;

    this.receiptStartAt = '';
    this.receiptEndAt = '';

    this.raceStartAt = '';
    this.raceEndAt = '';

    this.reward = '';
    this.rewardImgUrl = '';
    this.rewardImgUrlList = [];
    this.rewardDetails = '';

    this.currencyCode = '';
    this.feeAmount = 0;

    this.raceType = '';
    this.status = '';
  }

  setData(obj){
    const {
      uid,
      round,
      receiptStartAt,
      receiptEndAt,
      raceStartAt,
      raceEndAt,
      reward,
      rewardImgUrl,
      rewardDetails,
      currencyCode,
      feeAmount,
      raceType,
      status,
    } = obj;

    this.uid = uid;
    this.round = round;
    this.receiptStartAt = receiptStartAt;
    this.receiptEndAt = receiptEndAt;
    this.raceStartAt = raceStartAt;
    this.raceEndAt = raceEndAt;
    this.reward = reward;

    this.rewardImgUrl = rewardImgUrl;
    if(rewardImgUrl){
      this.rewardImgUrlList = [
        {
          src : rewardImgUrl,
        }
      ]
    }

    this.rewardDetails = rewardDetails;
    this.currencyCode = currencyCode;
    this.feeAmount = feeAmount;
    this.raceType = raceType;
    this.status = status;
  }
  getRegisterData(){
    let obj = {
      // round: Number(this.round),
      race_receipt_start_date: this.receiptStartAt,
      reward: this.reward,
      reward_img_url: this.rewardImgUrlList.length > 0 ? this.rewardImgUrlList[0].src : '',
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      reward: this.reward,
      reward_img_url: this.rewardImgUrlList.length > 0 ? this.rewardImgUrlList[0].src : '',
    }
    return obj;
  }
}