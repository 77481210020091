import StockGameModel from '@/views/stock/game/model/StockGameModel';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class StockGameDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
  }

  init(raceUid){
    this.model = new StockGameModel();
    if(raceUid){
      this.isModify = true;
      this.getStockGameDetail(raceUid);
    }
  }

  onCompleteFileSave(){
    if(this.isModify){
      this.postStockGameModify();
    }else{
      this.postStockGameRegister();
    }
  }

  getStockGameDetail(raceUid){
    const path = `${apiPath.STOCK_GAME_DETAIL.format(raceUid)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData.body);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  postStockGameRegister(){
    const path = `${apiPath.STOCK_GAME_REGISTER}`;
    const body = {
      body: this.model.getRegisterData(),
      header:{
        request_at: new Date()
      }
    };
    POST_REQUEST(path,body).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `모의투자가 등록되었습니다.`);
      router.push({ name: 'STOCK_GAME_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  postStockGameModify(){
    const path = `${apiPath.STOCK_GAME_MODIFY.format(this.model.uid)}`;
    const body = {
      body: this.model.getModifyData(),
      header:{
        request_at: new Date()
      }
    };
    POST_REQUEST(path,body).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `모의투자가 수정되었습니다.`);
      router.push({ name: 'STOCK_GAME_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}